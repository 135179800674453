<script setup>
defineComponent('NuHeader')

const localePath = useLocalePath()
const router = useRouter()
const auth = useAuthStore()
const headerTopBarItems = useRuntimeConfig().public.header?.headerTopBarItems
const {$isInMobileAppWebView} = useNuxtApp()
const {postMessageMyTickets} = usePostMessage()
const isSearchEnabled = useRuntimeConfig().public.isSearchEnabled

const authViewToggle = ref(false)

watchEffect(() => {
  if (auth.showAuthView) {
    authViewToggle.value = true
  }
})

const handleMyTicket = () => {
  if ($isInMobileAppWebView.value) {
    postMessageMyTickets()
    return
  } else {
    router.push(localePath({name: 'profile-my-tickets'}))
  }
}
</script>

<style lang="scss" scoped>
@import 'assets/css/components/nu-header.css';
</style>

<template>
  <header class="nu-header">
    <div class="top-bar">
      <ul class="container-xl">
        <li
          :key="`${index}-${headerTopBarItem}`"
          v-for="(headerTopBarItem, index) in headerTopBarItems"
        >
          <NuxtLink
            :to="localePath({path: `${headerTopBarItem.url}`})"
            prefetch
          >
            {{ $t(`comp_nu_header.${headerTopBarItem.text}`) }}
          </NuxtLink>
        </li>
      </ul>
    </div>
    <div class="container-xl">
      <div class="row header-main">
        <div class="col-4 d-flex align-items-center">
          <a
            id="nu-left-side-bar-handler"
            class="menu-icon"
            data-bs-target="#nu-left-side-bar-offcanvas"
            data-bs-toggle="offcanvas"
            href="#nu-left-side-bar-offcanvas"
            onclick="return false;"
          >
            <font-awesome-icon :icon="['fass', 'bars']" />
          </a>
          <figure class="d-flex p-t-2 m-0">
            <NuxtLink :to="localePath('index')">
              <div class="logo"></div>
            </NuxtLink>
          </figure>
          <a
            class="d-none d-md-block search-icon-mobile"
            href="#"
            v-if="isSearchEnabled"
          >
            <img
              alt="search"
              src="/images/common/search.svg"
              width="22"
            />
          </a>
        </div>

        <div class="col-8 links-desktop">
          <nav class="nav">
            <NuxtLink
              data-testid="my-tickets"
              v-show="auth.isLoggedIn"
              @click="handleMyTicket"
            >
              {{ $t('comp_nu_header.my_tickets') }}
            </NuxtLink>
            <NuHeaderLanguages />
            <NuHeaderProfile @showLoginPanel="authViewToggle = true" />
            <NuCart />
          </nav>
        </div>

        <div class="col-8 d-flex justify-content-end align-items-center d-md-none">
          <ul class="links">
            <li class="mobile-icon-links">
              <NuHeaderProfile @showLoginPanel="authViewToggle = true" />
            </li>
            <li class="mobile-icon-links">
              <NuxtLink @click="handleMyTicket">
                <img
                  alt="ticket"
                  class="my-tickets-icon default"
                  src="/images/common/ticket-icon.svg"
                />
                <img
                  alt="ticket"
                  class="my-tickets-icon active"
                  src="/images/common/ticket-icon-active.svg"
                />
              </NuxtLink>
            </li>
            <li class="mobile-icon-links">
              <NuHeaderLanguages :display="'NuSvgIcon'" />
            </li>
            <li class="mobile-icon-links">
              <NuCart />
            </li>
          </ul>
        </div>

        <div
          class="col-12 col-md-6 d-flex d-md-none search-outer"
          v-if="isSearchEnabled"
        >
          <div class="input-group mb-2 search-bar">
            <span class="search-icon">
              <img
                alt="search"
                src="/images/common/search.svg"
                width="22"
              />
            </span>

            <input
              :placeholder="$t('comp_nu_header.search_placeholder')"
              aria-describedby="basic-addon1"
              aria-label="Search"
              class="form-control"
              type="text"
            />

            <span class="search-close">
              <img
                alt="close"
                src="/images/common/close.svg"
                width="15"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      id="nu-left-side-bar-offcanvas"
      aria-labelledby="offcanvasTopLabel"
      class="offcanvas offcanvas-start"
      tabindex="-1"
    >
      <NuLeftSideBar @openAuthPanelClick="authViewToggle = true" />
    </div>
  </header>

  <NuRightPanelForAuth
    :panel-class="authViewToggle ? 'auth-panel-active' : 'auth-panel-hide'"
    v-show="authViewToggle"
    @closeAuthPanel="authViewToggle = false"
  />
</template>

<script setup>
defineComponent('NuFooter')
const {t} = useI18n()
const app = useNuxtApp()
const {dayjs} = useDayjs()
const footerItems = useRuntimeConfig().public.footer?.footerItems
const localePath = useLocalePath()
const footerCopyright = ref(
  t('comp_nu_footer.copyright', {
    company: (app.$themeData && app.$themeData.name) || '',
    year: dayjs().year(),
  }),
)
</script>

<style lang="scss" scoped>
@import 'assets/css/components/nu-footer.css';
</style>

<template>
  <footer class="nu-footer">
    <div class="container-xl">
      <NuSocialLinks id="socialLink" />
      <NuPaymentIcons id="paymentIcons" />
      <nav>
        <ul class="nu-footer-links">
          <li
            :key="`${index}-${footerItem}`"
            v-for="(footerItem, index) in footerItems"
          >
            <NuxtLink :to="localePath({path: `${footerItem.url}`})">
              {{ $t(`comp_nu_footer.${footerItem.text}`) }}
            </NuxtLink>
          </li>
        </ul>
      </nav>

      <div class="nu-footer-copyright">
        {{ footerCopyright }}
      </div>
    </div>
  </footer>
</template>
